"use client";

import {
  PayloadLexicalReactRenderer,
  type PayloadLexicalReactRendererContent,
} from "@atelier-disko/payload-lexical-react-renderer";
import { icons } from "@nextgen/shared";

import { Link } from "~/components/link";
import { type ThreeColumnsBlock } from "~/payload-types";

type Props = Omit<ThreeColumnsBlock, "blockName">;

export function ThreeColumnsBlockRender({ items }: Props) {
  if (!items) {
    return null;
  }

  return (
    <dl className="mx-auto grid w-full max-w-5xl grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3">
      {items.map((item) => {
        const Icon = icons[item.icon];
        const page = typeof item.page !== "number" ? item.page : undefined;
        return (
          <div key={item.id} className="flex flex-col">
            <dt className="flex items-center gap-x-3 text-base font-semibold leading-7">
              <Icon
                className="text-primary h-5 w-5 flex-none"
                aria-hidden="true"
              />
              {item.title}
            </dt>
            <dd className="text-muted-foreground mt-4 flex flex-auto flex-col text-base leading-7">
              <div className="flex-auto">
                <PayloadLexicalReactRenderer
                  content={
                    item.description as PayloadLexicalReactRendererContent
                  }
                />
              </div>
              {page && (
                <p className="mt-6">
                  <Link
                    href={page.slug}
                    className="text-primary text-sm font-semibold leading-6"
                  >
                    Läs mer <span aria-hidden="true">→</span>
                  </Link>
                </p>
              )}
            </dd>
          </div>
        );
      })}
    </dl>
  );
}
